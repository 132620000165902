<template>
    <div class="contact">
        <div class="banner">
            <img src="@/assets/about-banner.png" class="img"/>
            <div class="text">
                <img src="@/assets/about-text.png" />
            </div>
            <div class="note">The AI Intelligent Supply Chain Optimization Platform focuses on providing intelligent supply chain forecasting and optimization solutions for the manufacturing and retail sectors. We leverage real-time data collection and AI predictive and optimization models to support the full automation of the supply chain process from procurement to delivery, helping businesses gain significant cost advantages in fierce market competition.</div>
        </div>

        <div class="info-box">
            <div class="info-panel">
                <img src="@/assets/about-info-img.png" class="info-img"/>
                <div class="info-message">
                    <div class="title">
                        <span>Our Mission</span>
                        <img src="@/assets/star.png"/>
                    </div>
                    <div class="mark">The AI Intelligent Supply Chain Optimization Platform focuses on providing intelligent supply chain forecasting and optimization solutions for the manufacturing and retail sectors. We leverage real-time data collection and AI predictive and optimization models to support the full automation of the supply chain process from procurement to delivery, helping businesses gain significant cost advantages in fierce market competition.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        active: 1,
        value: ""
    })
}
</script>

<style scoped lang="scss">
.contact {
    position: relative;
}

.banner {
    position: relative;
    width: 100%;
    z-index: 10;

    > .img {
        width: 100%;
        height: 572px;
    }

    .text {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 192px;
        left: 157px;
        width: 292px;
        height: 68px;

        > img {
            width: 100%;
        }
    }

    .note {
        position: absolute;
        top: 276px;
        left: 157px;
        width: 564px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
    }
}

.info-box {
    position: relative;
    width: 100%;
    height: 680px;
    background-color: #FFFFFF;

    padding: 128px 133px 0 133px;
    overflow: hidden;
    z-index: 10;

    &::before {
        content: "";
        position: absolute;
        top: 382px;
        left: -267px;
        width: 1613px;
        height: 1613px;
        border-radius: 50%;
        background-image: radial-gradient(rgba(81, 82, 235, 1) 0%,rgba(106, 136, 255, 0) 78.34645669291339%);
        background-repeat: repeat;
        z-index: -1;
    }

    .info-panel {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        > .info-img {
            flex: none;
            width: 650px;
            height: 400px;
            margin-right: 45px;
        }

        .info-message {
            padding-top: 40px;

            .title {
                display: flex;
                align-items: center;
                height: 51px;
                margin-bottom: 42px;
                font-size: 36px;
                line-height: 51px;

                > img {
                    width: 24px;
                    height: 24px;
                    margin-left: 10px;
                }
            }

            .mark {
                font-size: 14px;
                color: #050505;
                line-height: 20px;
            }
        }
    }
}
</style>